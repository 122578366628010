.btn {
  border: none;
  color: white;
  background: #37a000;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0 4px;
  font-size: 13px;
  display: inline-block;
}
.disabled {
  background: #88d45d;
}
.stretch {
  width: -webkit-fill-available;
  text-align: center;
  height: fit-content;
}
.outlined {
  background: transparent;
  font-weight: bold;
  border: solid 2px #37a000;
  color: #37a000;
  border-radius: 30px;
  display: inline-block;
  margin: 10px 0;
  padding: 6px 20px;
}

.simpleOutlined{
  background: transparent;
  font-weight: bold;
  border: solid 2px #37a000;
  color: #37a000;
  border-radius: 4px;
  display: inline-block;
  margin: 10px 0;
  padding: 6px 20px;
  margin-right: 6px;
}
/* loader */
.spinner {
  /* margin: 100px auto; */
  /* width: 50px; */
  height: 15px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: delay-animation 1.2s infinite ease-in-out;
  animation: delay-animation 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes delay-animation {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes delay-animation {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* .lds_ring {
  border: 16px solid #f3f3f3;  
  border-top: 16px solid #3498db;  
  border-radius: 50%;
  --size: 15px;
  width: var(--size);
  height: var(--size);
  animation: lds_ring 2s linear infinite;
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
/* 
.lds_ring {
  --size: 15px;
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
}
.lds_ring div {
  --size: 19px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--size);
  height: var(--size);
  margin: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
