.my_account_btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.my_account_btn__label {
  margin-left: 12px;
}
@media screen and (max-width: 670px) {
  .my_account_btn__label {
    display: none;
  }
}
