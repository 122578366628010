.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.m10 {
  margin: 10px 0;
}
.m30 {
  margin: 30px 0;
}
