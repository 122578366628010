.spacing {
  margin-left: 0;
  margin: 14px;
}

@media screen and (max-width: 786px) {
  .spacing {
    margin: 16px;
    margin-left: 0;
  }
}

.spacing:hover {
  fill: #52ef00;
  color: #52ef00;
}
.margin {
  margin: 0 6px;
}
