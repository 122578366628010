.btn {
  border: none;
  color: white;
  background: #37a000;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0 4px;
}
.disabled {
  background: #ddd;
}
