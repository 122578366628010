/* shimmer */
.lightShimmerContainer {
  display: flex;
  align-items: center;
}
.lightShimmerContainer > * {
  visibility: hidden;
}
.lightShimmer {
  visibility: visible;
  display: inherit;
  height: 8px;
  width: 100px;
  position: absolute;
  border-radius: 10px;
}

.circle {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}

.smRadius {
  border-radius: 30px;
  margin: 0px 6px;
}

.circleLg {
  height: 60px;
  width: 60px;
}

.dNone {
  display: none;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  color: transparent;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}
.animate > * {
  visibility: hidden;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.stretch {
  display: flex;
  flex: 1;
}

.shrink {
  display: flex;
}
