.category_card_btn {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #eee;
  border-radius: 4px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px rgba(143, 143, 143, 0.25);
  padding: 12px 22px;
  cursor: pointer;
  margin: 8px;
  background: #fff;
}

.sm {
  padding: 12px 12px;
}

.selected {
  border: solid 1px lightskyblue;
  box-shadow: 0px 4px 4px rgb(135 206 250 / 54%);
}

.category_card_btn__label {
  height: 1.8rem;
  /* height: 3em; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  margin-top: 6px;
  display: flex;
  /* flex: 1; */
}

@media only screen and (max-width: 600px) {
  .category_card_btn {
    /* padding: 8px 4px; */
    padding: 8px 16px;
  }
  .category_card_btn img {
    /* height: 64px;
    width: 64px; */
  }
}

.hidden {
  display: none;
}
