.category_brief_info {
  display: flex;
  padding: 24px 0;
  flex-grow: 1;
}
.reversed {
  flex-direction: row-reverse;
}
.category_brief_info__left {
  margin-right: 24px;
  flex: 1;
  align-items: center;
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
}
.category_brief_info__right {
  margin-left: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
}

.category_brief_info__right h1 {
  margin: 4px 0;
}

.reversed .category_brief_info__left {
  justify-content: flex-end;
}

@media only screen and (max-width: 700px) {
  .category_brief_info {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  .category_brief_info__right {
    justify-content: center;
    align-items: center;
  }
  .category_brief_info__left,
  .category_brief_info__right,
  .category_brief_info__left.end {
    margin: 0;
    justify-content: center !important;
  }
  .briefInfoTxt {
    text-align: center;
  }
}

.category_brief_info__left.end {
  justify-content: flex-end;
}

.lg {
  font-size: 48px;
}

@media screen and (min-width: 786px) {
  /* .category_brief_info__left,
  .category_brief_info__right {
    justify-content: center;
  } */
  .category_brief_info__left .img,
  .category_brief_info__right .img {
    height: 400px;
    width: 400px;
  }
}

@media screen and (max-width: 786px) {
  .img_sm {
    height: 300px;
    width: 300px;
  }
  .lg {
    font-size: 32px !important;
  }
}
