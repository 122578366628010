.header {
  height: 56px;
  box-shadow: 0px 1px #ddd;
  display: flex;
  align-items: center;
  padding: 0 26px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 1;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right__login_btn {
  margin: 0 12px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 0 16px;
  }
  .txt_sm {
    font-size: 12px;
  }
  .btn_sm {
    padding: 5px 12px !important;
  }
}

.header_pad {
  height: 56px;
  width: 100%;
}

.header_left {
  display: flex;
  align-items: center;
}

.header_left img {
  margin-right: 8px;
}

.show_lg {
  display: none;
}
.hide_lg {
  display: block;
}

@media screen and (min-width: 864px) {
  .hide {
    display: none;
  }
  .show_lg {
    display: block;
  }
  .hide_lg {
    display: none !important;
  }
}
