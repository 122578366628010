.footer {
  background: #1d4354;
  color: #fff;
  padding: 72px;
}

.footer__column,
.footer__column__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer__column__bottom {
  align-items: center;
  margin-bottom: 16px;
}

.footer__column__item {
  margin-bottom: 10px;
}
@media screen and (max-width: 670px) {
  .footer {
    background: #1d4354;
    color: #fff;
    padding: 24px;
  }

  .footer__column__item.brief {
    width: auto !important;
  }
  .footer__column {
    flex-direction: column;
  }
  .wsm {
    display: block !important;
  }
  .wlg {
    display: none !important;
  }
  .sm_center {
    width: 100%;
  }
}
.wsm {
  display: none;
}
.wlg {
  display: block;
}

.footer__column__item.brief {
  width: 30%;
  margin-bottom: 20px;
}

.footer__column__item_right {
  display: flex;
}

.hidden {
  opacity: 0;
}

.link {
  color: #52ef00;
  text-decoration: none;
}

.footer__column__item__link {
  margin: 4px 0;
}
.footer__column__item__head {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 18px;
}

.footer__column__item_brief__head {
  margin-bottom: 18px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
