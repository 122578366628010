.my_account_sidebar {
  border-radius: 4px;
  border: solid 1px #eee;
  max-width: 240px;
  height: 100%;
  margin-right: 30px;
}

.my_account_sidebar_items_title {
  margin: 0;
  font-size: 16px;
  margin: 8px 0;
  padding: 4px 16px;
}

.my_account_sidebar_items_item {
  /* font-weight: lighter; */
  padding: 8px 24px;
  padding-right: 44px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  display: block;
}

.my_account_sidebar_items_item.selected {
  background: #d4f4c9;
}

.divider {
  border-bottom: solid 1px #eee;
}

.logout_btn {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.logout_btn span {
  margin-left: 8px;
}

@media only screen and (max-width: 730px) {
  .my_account_sidebar {
    width: 100%;
    margin: 6px;
  }
  .my_account__container {
    justify-content: center;
    padding: 4px !important;
    flex-direction: column;
    align-items: center;
  }

  /* .my_account__body,
  .my_account_sidebar {
    margin: 6px 0 !important;
  } */
}
@media only screen and (max-width: 864px) {
  .hide_sm {
    display: none;
  }
}
