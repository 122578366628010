.body_container {
  /* justify-content: center;
  display: flex;
  flex-direction: column; */
  width: 95%;

  margin: 0 auto;
  margin-bottom: 40px;
}
.our_services {
  background: #fafafa;
  padding: 10px 0 20px 0;
  border-radius: 4px;
}

.our_services {
  text-align: center;
}
